'use client';

import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

import { IconArrowLeft } from '@/icons/IconArrowLeft';

export default function ErrorPage({
  error,
}: {
  error: Error & { digest?: string };
}) {
  const pathname = usePathname();

  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);

    if ((window as any).newrelic) {
      (window as any).newrelic.noticeError(error);
    }
  }, [error]);

  const handleRefresh = () => {
    window.location.replace(pathname);
  };

  return (
    <div className='flex h-screen flex-col'>
      <div className='mx-auto flex max-w-5xl flex-1 flex-col items-center gap-x-6 px-18 pt-10 sm:flex-row sm:px-20'>
        <div className='mb-6'>
          <Image
            src={{ width: 406, height: 492.07, src: '/images/500.svg' }}
            alt='error message'
          />
        </div>

        <div className='max-w-[25.375rem] p-4'>
          <h1 className='mb-2 text-2xl font-bold text-blueGray-900'>
            Unexpected error
          </h1>
          <p className='text-sm text-blueGray-500'>
            Apologies for the inconvenience, an unexpected error has occurred.
            Rest assured, we&apos;re working to resolve it promptly. Please try
            again later. Thank you for your patience.
          </p>

          <button
            type='button'
            className='flex items-center gap-x-3 mt-4 text-sm font-semibold text-purpleBlue-600'
            onClick={handleRefresh}
          >
            <IconArrowLeft className='stroke-current h-4' />
            Back
          </button>
        </div>
      </div>
    </div>
  );
}
